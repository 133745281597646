var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-form-model",
        {
          ref: "addInfo",
          attrs: { "label-col": _vm.labelCol, "wrapper-col": _vm.wrapperCol },
        },
        [
          _c(
            "div",
            { staticClass: "switch_box" },
            [
              _c("span", { staticClass: "switch_title" }, [
                _vm._v(_vm._s(_vm.switchTitle)),
              ]),
              _c("a-switch", {
                attrs: { size: "small" },
                on: { change: _vm.onChange },
                model: {
                  value: _vm.isOpen,
                  callback: function ($$v) {
                    _vm.isOpen = $$v
                  },
                  expression: "isOpen",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isOpen,
                  expression: "isOpen",
                },
              ],
            },
            [
              _c(
                "a-form-model-item",
                { attrs: { label: "参保主体", prop: "" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        "city-code": "item.cityCode",
                        "allow-clear": "",
                        placeholder: "请选择参保主体",
                      },
                      on: { change: _vm.changeInsuredEntity },
                      model: {
                        value: _vm.entryInsurance.insureCompanyId,
                        callback: function ($$v) {
                          _vm.$set(_vm.entryInsurance, "insureCompanyId", $$v)
                        },
                        expression: "entryInsurance.insureCompanyId",
                      },
                    },
                    _vm._l(_vm.insureCompanyList, function (it) {
                      return _c(
                        "a-select-option",
                        {
                          key: it.companyId,
                          attrs: {
                            "city-code": it.cityCode,
                            value: it.companyId,
                          },
                        },
                        [_vm._v(_vm._s(it.companyName))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "参保方案", prop: "" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        "allow-clear": "",
                        placeholder: "请选择参保方案",
                      },
                      on: { change: _vm.changeSchemeList },
                      model: {
                        value: _vm.entryInsurance.schemeId,
                        callback: function ($$v) {
                          _vm.$set(_vm.entryInsurance, "schemeId", $$v)
                        },
                        expression: "entryInsurance.schemeId",
                      },
                    },
                    _vm._l(_vm.schemeList, function (it) {
                      return _c(
                        "a-select-option",
                        { key: it.schemeId, attrs: { value: it.schemeId } },
                        [_vm._v(_vm._s(it.schemeName))]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm.type === "housing"
                ? _c(
                    "a-form-model-item",
                    { attrs: { label: "公积金账号", prop: "" } },
                    [
                      _c("a-input", {
                        attrs: { placeholder: "以前在本地的话需要录入" },
                        model: {
                          value: _vm.entryInsurance.accountNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.entryInsurance, "accountNum", $$v)
                          },
                          expression: "entryInsurance.accountNum",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "a-form-model-item",
                { attrs: { label: "缴纳基数", prop: "" } },
                [
                  _c(
                    "a-radio-group",
                    {
                      on: { change: _vm.changeBaseNum },
                      model: {
                        value: _vm.entryInsurance.paymentBaseType,
                        callback: function ($$v) {
                          _vm.$set(_vm.entryInsurance, "paymentBaseType", $$v)
                        },
                        expression: "entryInsurance.paymentBaseType",
                      },
                    },
                    [
                      _vm._l(_vm.paymentBaseList, function (item) {
                        return _c(
                          "a-radio",
                          { key: item.value, attrs: { value: item.value } },
                          [_vm._v(" " + _vm._s(item.label) + " ")]
                        )
                      }),
                      _vm.entryInsurance.paymentBaseType === 1
                        ? _c("CbNumber", {
                            attrs: {
                              "decimal-separator": true,
                              placeholder: "请输入基数",
                              suffix: "元",
                            },
                            model: {
                              value: _vm.entryInsurance.fixedPaymentBase,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.entryInsurance,
                                  "fixedPaymentBase",
                                  $$v
                                )
                              },
                              expression: "entryInsurance.fixedPaymentBase",
                            },
                          })
                        : _c(
                            "div",
                            { staticClass: "unit_input" },
                            [
                              _c(
                                "a-row",
                                { attrs: { gutter: 24 } },
                                [
                                  _c(
                                    "a-col",
                                    { attrs: { span: 16 } },
                                    [
                                      _c("DictionariesInput", {
                                        staticClass: "dictionariesInput",
                                        attrs: {
                                          placeholder: "请选择自定义金额",
                                          parameter: "custom_scale_type",
                                        },
                                        model: {
                                          value:
                                            _vm.entryInsurance.customScaleType,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.entryInsurance,
                                              "customScaleType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "entryInsurance.customScaleType",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "a-col",
                                    { attrs: { span: 8 } },
                                    [
                                      _c("CbNumber", {
                                        attrs: {
                                          "decimal-separator": true,
                                          max: 100,
                                          suffix: "%",
                                          placeholder: "请输入比例",
                                        },
                                        model: {
                                          value: _vm.entryInsurance.customScale,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.entryInsurance,
                                              "customScale",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "entryInsurance.customScale",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                    ],
                    2
                  ),
                ],
                1
              ),
              _c(
                "a-form-model-item",
                { attrs: { label: "缴纳起始月", prop: "" } },
                [
                  _c("a-month-picker", {
                    attrs: {
                      "value-format": "YYYY-MM",
                      placeholder: "选择日期",
                    },
                    model: {
                      value: _vm.entryInsurance.startMonth,
                      callback: function ($$v) {
                        _vm.$set(_vm.entryInsurance, "startMonth", $$v)
                      },
                      expression: "entryInsurance.startMonth",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }